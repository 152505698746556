@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&display=swap');

* {
  margin: 0px;
  padding: 0px;
  list-style: none;
  text-decoration: none;
  outline: none;
  font-family: 'DM Sans', sans-serif;
}

html {
	box-sizing: border-box;
}
*, *::before, *::after {
	box-sizing: inherit;
}

img {
  display: block;
  max-width: 100%;
}